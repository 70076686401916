// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["f9B1E3MqE", "dgfBNM2ID"];

const variantClassNames = {dgfBNM2ID: "framer-v-12mrmow", f9B1E3MqE: "framer-v-qskry6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "f9B1E3MqE", "wh-18": "dgfBNM2ID"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "f9B1E3MqE"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "f9B1E3MqE", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-wp1a2", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-qskry6", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"f9B1E3MqE"} ref={ref} style={{...style}} {...addPropertyOverrides({dgfBNM2ID: {"data-framer-name": "wh-18"}}, baseVariant, gestureVariant)}><SVG className={"framer-ia8lla"} data-framer-name={"Icons"} fill={"black"} intrinsicHeight={20} intrinsicWidth={20} layoutDependency={layoutDependency} layoutId={"A56D4GfHp"} svg={"<svg width=\"20\" height=\"20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g clip-path=\"url(#a)\"><path d=\"M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z\" fill=\"#878786\"/><path d=\"m7 13.5 6-6m0 6-6-6\" stroke=\"#fff\" stroke-width=\"1.25\" stroke-linecap=\"round\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h20v20H0z\"/></clipPath></defs></svg>"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-wp1a2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wp1a2 .framer-1r6pjhq { display: block; }", ".framer-wp1a2 .framer-qskry6 { height: 20px; overflow: hidden; position: relative; width: 20px; }", ".framer-wp1a2 .framer-ia8lla { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-wp1a2.framer-v-12mrmow .framer-qskry6 { height: 18px; width: 18px; }", ".framer-wp1a2.framer-v-12mrmow .framer-ia8lla { height: var(--framer-aspect-ratio-supported, 18px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"dgfBNM2ID":{"layout":["fixed","fixed"]}}}
 */
const FramerIOSYqykab: React.ComponentType<Props> = withCSS(Component, css, "framer-wp1a2") as typeof Component;
export default FramerIOSYqykab;

FramerIOSYqykab.displayName = "Gray Chack";

FramerIOSYqykab.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerIOSYqykab, {variant: {options: ["f9B1E3MqE", "dgfBNM2ID"], optionTitles: ["Variant 1", "wh-18"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerIOSYqykab, [])